import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss']
})
export class ConfirmationDialogComponent implements OnInit {
  public message: string = '';

  constructor(public dialogRef: MatDialogRef<ConfirmationDialogComponent>,private ts: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, messageParams: any }) {
      if (this.data.messageParams) {
        this.message = this.ts.instant(this.data.message, this.data.messageParams);
      } else {
        this.message = this.ts.instant(this.data.message);
      }
  }

  ngOnInit(): void {
  }

}
