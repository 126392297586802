import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'  // This ensures the service is provided at the root level and available globally
})
export class CustomPaginationLocalization extends MatPaginatorIntl {

  itemsPerPageLabel = 'Items per page';
  nextPageLabel = 'Next';
  previousPageLabel = 'Previous';
  firstPageLabel = 'First page';
  lastPageLabel = 'Last page';

  // Observable to trigger when the language changes
  changes: Subject<void> = new Subject<void>();

  constructor(private ts: TranslateService) {
    super();
  }

  getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 of ${length}`;
    }
    const startIndex = page * pageSize;
    const endIndex = Math.min(startIndex + pageSize, length);
    return `${startIndex + 1} – ${endIndex} of ${length}`;
  }

  // Method to set labels dynamically based on language
  setLabels() {
    this.itemsPerPageLabel = this.ts.instant('PAGINATOR.ITEMS_PER_PAGE');
    this.nextPageLabel = this.ts.instant('PAGINATOR.NEXT_PAGE');
    this.previousPageLabel = this.ts.instant('PAGINATOR.PREVIOUS_PAGE');
    this.firstPageLabel = this.ts.instant('PAGINATOR.FIRST_PAGE');
    this.lastPageLabel = this.ts.instant('PAGINATOR.LAST_PAGE');

    // Emit a change event so that the paginator updates its labels
    this.changes.next();
  }
}
