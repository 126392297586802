import { Component, OnInit } from '@angular/core';
import { LanguageService } from '../../services/language.service';
import { CustomPaginationLocalization } from '../../utils/custom-pagination-localization/custom-pagination-localization';
import { MatPaginatorIntl } from '@angular/material/paginator';

@Component({
  selector: 'app-language-change',
  templateUrl: './language-change.component.html',
  styleUrls: ['./language-change.component.scss'],
  providers: [{ provide: MatPaginatorIntl, useClass: CustomPaginationLocalization }]
})
export class LanguageChangeComponent implements OnInit {
  language: string;
  langToShow: string[];
  languageList = ['en', 'bg'];
  constructor(private languageService: LanguageService, private paginatorIntl: CustomPaginationLocalization) { }

  ngOnInit(): void {
    this.getCurrLanguage();
  }

  getCurrLanguage() {
    this.language = this.languageService.getCurrentUserLang;
    this.paginatorIntl.setLabels();
    this.langToShow = this.languageList.filter(x => x != this.language);
  }

  changeLanguage(lang: string) {
    this.language = lang;
    this.languageService.setCurrentLanguage(lang);
    this.paginatorIntl.setLabels();
    this.langToShow = this.languageList.filter(x => x != this.language);
  }
}

