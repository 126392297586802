import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChatButtonComponent } from './components/chat-button/chat-button.component';
import { ChatMenuComponent } from './components/chat-menu/chat-menu.component';
import { ChatWindowComponent } from './components/chat-window/chat-window.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { InfoDialogComponent } from './components/info-dialog/info-dialog.component';
import { PreviewDialogComponent } from './components/preview-dialog/preview-dialog.component';
import { VideoCallsComponent } from './components/video-calls/video-calls.component';
import { IncomingCallDialogComponent } from './components/incoming-call-dialog/incoming-call-dialog.component';
import { ShareModule } from '../share.module';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';

@NgModule({
  declarations: [ChatButtonComponent, ChatMenuComponent, ChatWindowComponent, InfoDialogComponent, PreviewDialogComponent, VideoCallsComponent, IncomingCallDialogComponent, ConfirmationDialogComponent],
  imports: [
    CommonModule,
    FormsModule,
    ShareModule,
    MatIconModule,
    MatDialogModule
  ],
  exports: [
    ChatButtonComponent,
    ChatMenuComponent,
    ChatWindowComponent
  ]
})
export class ChatModule { }
