export interface IDoctor {
    id: number | string | undefined | null;
    name: string | undefined | null;
    experience: string | undefined | null;
    shortResume: string | undefined | null;
    longResume: string | undefined | null;
    image: string | undefined | null;
    specialties: string[] | any[] | [] | undefined | null;
    price: number | undefined | null;
    rating: number | undefined | null;
    reviewsCount: number | undefined | null;
    appointmentsCount: number | undefined | null;
    firstAvailableHour: number | undefined | null;
    isFavorite: boolean | undefined | null;
    reviews?: IDoctorReview[] | [] | undefined | null;
    city: string | undefined | null;
    country: string | undefined | null;
    canReview?: boolean | undefined | null;
}

export interface IDoctorAdditionalInfo {
    doctorId: number | undefined | null;
    price: number | undefined | null;
    rating: number | undefined | null;
    reviewsCount: number | undefined | null;
    appointmentsCount: number | undefined | null;
    firstAvailableHour: Date | string | undefined | null;
    isFavorite?: boolean | undefined | null;
    reviews?: IDoctorReview[] | [] | undefined | null;
}

export interface IDoctorReview {
    id: number | undefined | null;
    dateCreated: Date | string | undefined | null;
    patientId?: number | undefined | null;
    doctorId?: number | undefined | null;
    patientName: string | undefined | null;
    rating: number | undefined | null;
    text: string | undefined | null;
}

export interface IDoctorSchedule {
    doctorId: 0;
    weekdays: [
        {
            day: number | undefined | null,
            workingHours: [
                {
                    from: string | undefined | null,
                    to: string | undefined | null
                }
            ] | [] | undefined | null,
            isOff: boolean | null
        }
    ] | [] | undefined | null;
    daysOff: Date[] | string[] | undefined | null;
}

export interface IDoctorScheduleAvailableHours {
    hours: number[] | string[] | undefined | null;
}

export interface IDoctorrScheduleAvailableDays {
    days: number[] | string[] | undefined | null;
}

export interface IMedicalSpecialty {
    MedicalSpecialtyID: number | undefined | null;
    MedicalSpecialtyName: string | undefined | null;
}

export enum AppointmentStatuses {
    "NEW" = "NEW",
    "APPROVED" = "APPROVED",
    "DENIED" = "DENIED",
    "CANCELED" = "CANCELED",
    "ARCHIVED" = "ARCHIVED"
}

export interface IAppointmentAnswer {
    answeredAt: Date | string | undefined | null;
    denialReason: string | undefined | null;
}

export interface IAppointment {
    id: number | undefined | null;
    doctorId: number | undefined | null;
    patientId: number | undefined | null;
    patientName: string | undefined | null;
    doctorName: string | undefined | null;
    doctorImage: string | undefined | null;
    doctorSpecialties: string[] | [] | undefined | null;
    doctorCity: string | undefined | null;
    doctorRating: number | string | undefined | null;
    scheduledAt: Date | string | undefined | null;
    description: string | undefined | null;
    status: AppointmentStatuses | undefined | null;
    isArchived: boolean | undefined | null;
    files: IFileAttachment[] | [] | undefined | null;
    answer: IAppointmentAnswer | undefined | null;
    cost: number | string | undefined | null;
    roomId?: number | undefined | null;
}

export interface IFileAttachment {
    serverName: string | undefined | null;
    fileName: string | undefined | null;
    mimeType: string | undefined | null;
}