import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ChatMenuComponent } from '../chat-menu/chat-menu.component';
import { ChatWindowComponent } from '../chat-window/chat-window.component';
import { SocketIoService, SocketStates } from '../../../services/socket-io.service';
import { ChatService, VideoCallStatus } from '../../services/chat.service';
import { DialogService } from '../../services/dialog.service';
import { Subscription } from 'rxjs';
import { VideoCallsComponent } from '../video-calls/video-calls.component';
import { IncomingCallDialogComponent } from '../incoming-call-dialog/incoming-call-dialog.component';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import { Router } from '@angular/router';
import { SubscriptionPlan } from '../../constants/utils';

@Component({
  selector: 'app-chat-button',
  templateUrl: './chat-button.component.html',
  styleUrls: ['./chat-button.component.scss'],
})
export class ChatButtonComponent implements OnInit, OnDestroy {
  @ViewChild('ringTone') ringTone!: ElementRef;
  @ViewChild('hangupTone') hangupTone!: ElementRef;

  @Input('room') room: any;
  @Input('visitId') visitId: any;
  @Input('chatTitle') chatTitle: any;
  @Input('userId') userId: any;
  @Input('name') name: string;
  @Input('usedInMedCenter') usedInMedCenter: boolean = false;
  @Input('userUid') userUid: string = undefined;

  public videoCallAllowed: boolean = false;
  public chatAllowed: boolean = false;
  public videoCallPricePerMinute: number = null;
  public userCredits: number = 0;
  private userActiveSubscriptionPlan!: SubscriptionPlan;
  private patientUnderObservation: boolean = false;

  public menuOpened: boolean = false;
  public dialogRef: any;
  public unreadCount: number = 0;
  public lastReadTimestamp: string;
  public subs: Subscription = new Subscription();
  public unreadMessagesIds: Set<any> = new Set<any>();
  public socketIsConnected: boolean = false;
  private incomingCallDialogs: Map<string, MatDialogRef<IncomingCallDialogComponent>> = new Map();
  public chatLoading: boolean = true;

  constructor(
    public dialog: MatDialog,
    private socketIO: SocketIoService,
    private chatService: ChatService,
    private dialogService: DialogService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.testDialogService();
    this.getUserActiveSubscriptionPlan();
    this.subs.add(this.socketIO.socketState$.subscribe((state: SocketStates) => {
      if (state == SocketStates.open) {
        console.log('chat button connected to socket');
        this.socketIsConnected = true;
        this.getUserCredits();
        this.getVideoCallPricePerMinute();
        this.joinChatRoom();
        this.getUnreadMessages();
        this.listenForNewMessages();
        this.receiveVideoCalls();
        this.listenForEndedCall();
      } else if (state == SocketStates.reconnect) {
        this.socketIsConnected = true;
        this.joinChatRoom();
      } else {
        console.error('lost connection to socket');
        this.socketIsConnected = false;
        this.dialog.closeAll();
      }
    }));
    //check for unread messages
    this.subs.add(this.chatService.unreadMessages$
      .subscribe((unreadMsgs: Map<string, number>) => {
        let count = unreadMsgs.get(this.room);
        this.unreadCount = count;
      })
    );
  }

  ngOnDestroy(): void {
    this.pauseRingTone();
    this.subs.unsubscribe();
    this.removeSocketEventListeners();
  }

  openChatMenu() {
    let minHeight: any = 200;
    let width: any = 400;
    const dialogRef = this.dialog.open(ChatMenuComponent, {
      width: `${width}px`, // Set the width of the dialog,
      minHeight: `${minHeight}px`,
      position: { bottom: '6rem', right: '6rem' },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);

    });
  }

  openChatWindow() {
    if (!this.canUseDirectChat()) {
      return;
    }

    this.getUnreadMessages();
    this.socketIO.stopListeningForChatMessages();
    let minHeight: any = 80;
    let width: any = 400;

    let data: any = {
      visitId: this.visitId,
      room: this.room,
      lastReadTimestamp: this.lastReadTimestamp,
      chatTitle: this.chatTitle,
      userId: this.userId,
      name: this.name,
      videoCallAllowed: this.videoCallAllowed,
      videoCallPricePerMinute: this.videoCallPricePerMinute,
      userCredits: this.userCredits,
      usedInMedCenter: this.usedInMedCenter
    }

    const dialogRef = this.dialog.open(ChatWindowComponent, {
      data,
      width: `${width}px`, // Set the width of the dialog,
      minHeight: `${minHeight}vh`,
      position: { bottom: '6rem', right: '6rem' },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      this.getUnreadMessages();
      this.joinChatRoom();
      this.listenForNewMessages();
    });
  }

  getUnreadMessages() {
    this.socketIO.getUnreadMessages(this.room, (response: any) => {
      console.log("unread messages", response);
      if (response && response.success === true) {
        console.log('set unread messages', response.data.count);
        this.chatService.setUnreadMessagesCount(this.room, response.data.count);
        this.lastReadTimestamp = response.data.time;
      }
    });
  }

  joinChatRoom() {
    this.socketIO.joinChatRoom(this.room);
  }

  listenForNewMessages() {

    this.socketIO.receiveChatMessage((message: any) => {
      console.log('count message', message.room);
      if (message.room == this.room && !this.unreadMessagesIds.has(message._id)) {
        this.unreadMessagesIds.add(message._id);
        let newCount = this.unreadCount + 1;
        if (!this.lastReadTimestamp) {
          this.lastReadTimestamp = message.time;
        }
        this.chatService.setUnreadMessagesCount(this.room, newCount);
      }
    })
  }

  startVideoCall(initiator: boolean = true, callerName: string = undefined, callerHash: string = undefined) {
    let data: any = {
      initiator,
      roomId: this.room,
      userId: this.userId,
      socketService: this.socketIO,
      name: this.name,
      callerName,
      callerHash,
      scheduledCall: false
    };

    const dialogRef = this.dialog.open(VideoCallsComponent, {
      data,
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
    });
  }

  receiveVideoCalls() {
    console.log('receive video calls');
    this.socketIO.listenForIncomingCalls((roomId: any, peerHash: any, userId: any, username: any) => {
      console.log('incoming call', roomId, peerHash, userId, username);
      if (userId != this.userId && this.room == roomId) {
        this.playRingTone();
        let callData: any = {
          chatRoom: this.room,
          callerName: username,
          callerHash: peerHash
        }
        this.showIncomingCallDialog(callData);
      }
    })
  }

  showIncomingCallDialog(callData: { chatRoom: string, callerName: any, callerHash: any }) {
    let position = this.dialogService.calculateDialogPosition();

    let dialog = this.dialog.open(IncomingCallDialogComponent, {
      data: callData,
      autoFocus: false,
      restoreFocus: false,
      width: '360px',
      height: '230px',
      position
    });

    this.incomingCallDialogs.set(callData.chatRoom, dialog);

    this.dialogService.addDialog(dialog);

    let timeout = setTimeout(() => {
      clearTimeout(timeout);
      dialog.close('decline');
      this.chatService.setCallStatus(VideoCallStatus.cancelled);
      this.dialogService.removeDialog(dialog);
      this.incomingCallDialogs.delete(callData.chatRoom);
    }, 60 * 1000); // close automatically after 1 minute

    dialog.afterClosed().subscribe((result) => {
      clearTimeout(timeout);
      this.pauseRingTone();
      this.dialogService.removeDialog(dialog);
      if (result) {
        switch (result) {
          case 'accept':
            this.startVideoCall(false, callData?.callerName, callData?.callerHash);
            this.chatService.setCallStatus(VideoCallStatus.started);
            this.dialogService.closeAllDialogs('decline');
            break;
          case 'decline':
            console.log('call declined');
            this.socketIO.rejectCall(this.room);
            this.chatService.setCallStatus(VideoCallStatus.cancelled);
            this.playHangupTone();
            break;
          default:
            break;
        }
      }
      this.incomingCallDialogs.delete(callData.chatRoom);
    });
  }

  playRingTone() {
    if (!this.ringTone) {
      return;
    }

    this.ringTone.nativeElement.play();
  }

  pauseRingTone() {
    if (!this.ringTone) {
      return;
    }

    this.ringTone.nativeElement.pause();
  }

  playHangupTone() {
    if (!this.hangupTone) {
      return;
    }

    this.hangupTone.nativeElement.play();
  }

  testDialogService() {
    let dialogNum = 5;
    for (let i = 0; i < dialogNum; i++) {
      let timeout = setTimeout(() => {
        this.showIncomingCallDialog({ chatRoom: `room${i}`, callerName: `caller${i}`, callerHash: `callerHash${i}` });
        clearTimeout(timeout);
      }, 100);
    }

    // let intervalDuration = dialogNum * 1000;
    // let timeSpent = 0;
    // let closedRooms = 0;
    // let interval = setInterval(() => {
    //   console.log('interval active');
    //   timeSpent += 1000;
    //   let room = `room${closedRooms}`;
    //   this.room = room;
    //   closedRooms++;
    //   this.removeDialog(room);
    //   if (timeSpent >= intervalDuration) {
    //     clearInterval(interval);
    //   }
    // }, 1000);
  }

  // for test purposes
  removeDialog(roomId: any) {
    if (this.incomingCallDialogs.size > 0 && roomId && roomId == this.room) {
      const dialogRef = this.incomingCallDialogs.get(roomId);
      if (dialogRef) {
        dialogRef.close(); // Optionally close the dialog if needed
        this.incomingCallDialogs.delete(roomId);
      }
    }
  }

  listenForEndedCall() {
    this.socketIO.listenForCallEnd((roomId: any) => {
      if (this.incomingCallDialogs.size > 0 && roomId && roomId == this.room) {
        const dialogRef = this.incomingCallDialogs.get(roomId);
        if (dialogRef) {
          dialogRef.close(); // Optionally close the dialog if needed
          this.incomingCallDialogs.delete(roomId);
        }
      }
    });
  }

  removeSocketEventListeners() {
    this.socketIO.removeEventListeners('patient-unseen-messages');
    this.socketIO.removeEventListeners('patient-message');
    this.socketIO.removeEventListeners('receive-offer');
    this.socketIO.removeEventListeners('call-ended');
  }

  showInfoDialog(title: string, message: string, buttonText: string = undefined, redirectTo: string = undefined) {
    const dialogRef = this.dialog.open(InfoDialogComponent, {
      data: { title, message, buttonText },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (redirectTo && !this.usedInMedCenter) {
        switch (redirectTo) {
          case 'buy-credits':
            this.router.navigate(['/billing/buy-credits']);
            break;
          case 'subscriptions':
            this.router.navigate(['/billing/subscription-plans']);
            break;
          case 'medical-observation': 
            this.router.navigate(['/billing/request-medical-observation']);
            break;
          default:
            break;
        }
      }
    });
  }

  protected getUserCredits() {
    if (!this.userUid) {
      return;
    }

    this.chatService.getUserCredits(this.userUid).subscribe({
      next: (response: any) => {
        if (response && response.credits) {
          this.userCredits = response.credits;
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    });
  }

  getUserActiveSubscriptionPlan() {
    if (!this.userUid) {
      return;
    }
    this.chatService.getUserActiveSubscription(this.userUid).subscribe({
      next: (response: any) => {
        if (response) {
          this.userActiveSubscriptionPlan = response;
          this.videoCallAllowed = this.userActiveSubscriptionPlan.videoCalls;
          this.chatAllowed = this.userActiveSubscriptionPlan.directChat;
        }
      },
      error: (err: any) => console.error(err),
      complete: () => {
        this.getObservationStatus();
      }
    });
  }

  getVideoCallPricePerMinute() {
    if (!this.userUid) {
      return;
    }
    this.chatService.getVideoCallPricePerMinute(this.userUid).subscribe({
      next: (response: any) => {
        if (response) {
          this.videoCallPricePerMinute = response;
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    });
  }

  getObservationStatus(): void {
    this.chatService.getPatientObservationStatus(this.userUid).subscribe({
      next: (response: any) => {
        this.patientUnderObservation = response.underObservation;
      },
      error: (err: any) => { },
      complete: () => {
        this.chatLoading = false;
      }
    });
  }

  canUseDirectChat(): boolean {
    if (!this.chatAllowed) {
      this.showInfoDialog(
        'INFO_DIALOG.TITLES.Upgrade_subscription',
        'INFO_DIALOG.MESSAGES.Subscription_direct_chat',
        !this.usedInMedCenter ? 'INFO_DIALOG.BUTTONS.To_subscriptions' : 'INFO_DIALOG.BUTTONS.Close',
        'subscriptions'
      );
      return false;
    }

    if (!this.patientUnderObservation) {
      this.showInfoDialog(
        'INFO_DIALOG.TITLES.Not_under_observation',
        'INFO_DIALOG.MESSAGES.Medical_observation',
        !this.usedInMedCenter ? 'INFO_DIALOG.BUTTONS.To_request_observation' : 'INFO_DIALOG.BUTTONS.Close',
        'medical-observation'
      );
      return false;
    }

    return true;
  }
}
