<header *ngIf="!mobileAppObj">
	<div class="header-top">
		<div class="logo-wraper" (click)="gotToHome()">
			<!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="53.075" height="46.062"
				viewBox="0 0 53.075 46.062">
				<defs>
					<clipPath id="clip-path">
						<path id="Path_10655" data-name="Path 10655"
							d="M446.587,1183.229c-9.495,2.336-13.817,12.366-9.532,21.758.895,1.961,1.941,2.83,4.267,2.852,3.223.032,5.882-.212,7.5-3.549.69-1.427,2.148-1.264,3.2.024.523.641.992,1.326,1.706,2.288,1.039-4.277,2-8.174,2.927-12.079.274-1.155.641-2.244,2.055-2.228,1.431.016,1.765,1.153,1.982,2.3.415,2.192.814,4.388,1.226,6.581q1.023,5.457,2.053,10.912l.438.019c1.528-2.76,3.022-5.541,4.6-8.275,1.082-1.878,2.915-2,3.622-.054,1.294,3.562,3.686,4.559,7.13,3.954a1.733,1.733,0,0,1,.378-.01c.06,0,.12.005.18,0,5.655,0,5.737-.062,7.449-5.429a15.031,15.031,0,0,0-6.38-17.348,14.467,14.467,0,0,0-18.082,2.317,7.779,7.779,0,0,0-1.013,1.77c-1.167-1.151-1.757-1.79-2.406-2.362a14.33,14.33,0,0,0-9.641-3.9,15.226,15.226,0,0,0-3.651.459"
							transform="translate(-435.398 -1182.77)" fill="#d50017" />
					</clipPath>
					<clipPath id="clip-path-2">
						<path id="Path_10656" data-name="Path 10656"
							d="M464.119,1235.656c-.187.66-.894,1.562-1.449,1.628a3.128,3.128,0,0,1-2.141-.988,25.582,25.582,0,0,1-2.323-3.1c-1.271,3.909-3.937,3.829-6.677,3.749a17.409,17.409,0,0,0-2.6.052,4.161,4.161,0,0,0,.315.674c5.53,6.992,12.476,12.354,19.894,17.152a2.175,2.175,0,0,0,1.932-.235c3.954-2.875,7.984-5.674,11.708-8.83,2.98-2.525,5.563-5.517,8.322-8.3l-.355-.552c-2.146,0-4.306-.153-6.435.041-2.387.218-3.941-.58-4.751-2.849a4.938,4.938,0,0,0-.791-1.053c-1.81,3.273-3.452,6.4-5.27,9.412-.432.716-1.535,1.568-2.184,1.463-.67-.109-1.494-1.233-1.677-2.043-.782-3.438-1.343-6.926-2-10.392-.3-1.567-.631-3.127-.948-4.69-1.085,3-1.746,5.955-2.571,8.866"
							transform="translate(-448.931 -1226.79)" fill="#d50017" />
					</clipPath>
				</defs>
				<g id="Group_24351" data-name="Group 24351" transform="translate(0 0.001)">
					<g id="Group_24748" data-name="Group 24748" transform="translate(0 -0.001)">
						<g id="Group_24724" data-name="Group 24724" transform="translate(0 0)" clip-path="url(#clip-path)">
							<rect id="Rectangle_11255" data-name="Rectangle 11255" width="61.669" height="62.512"
								transform="matrix(0.691, -0.723, 0.723, 0.691, -17.985, 14.655)" fill="#d50017" />
						</g>
					</g>
					<g id="Group_24749" data-name="Group 24749" transform="translate(5.501 17.892)">
						<g id="Group_24726" data-name="Group 24726" transform="translate(0 0)" clip-path="url(#clip-path-2)">
							<rect id="Rectangle_11256" data-name="Rectangle 11256" width="49.601" height="50.044"
								transform="matrix(0.691, -0.723, 0.723, 0.691, -14.139, 14.794)" fill="#d50017" />
						</g>
					</g>
				</g>
			</svg>
			<span class="header-title">{{ 'LAYOUT.TITLE.Header' | translate }}</span> -->
			<img src="assets/images/myki-care-logo.svg" alt="logo">
		</div>
		<!-- <div class="header-link" routerLink="/medical-report" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])"
			style="margin-left: auto;">
			Medical Report
		</div> -->
		<!-- <monitoring></monitoring> -->
		<div class="header-link" routerLink="/home" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])">
			{{ 'LAYOUT.LINKS.Home' | translate }}
		</div>

		<!-- <div class="header-link" mat-button [matMenuTriggerFor]="menu">{{ 'LAYOUT.LINKS.Appointments' | translate }}</div>
		<mat-menu #menu="matMenu">
			<div class="header-link" routerLink="/appointments/doctors-list" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])" mat-menu-item>
				{{ 'LAYOUT.LINKS.Doctors_list' | translate }}
			</div>
			<div class="header-link" routerLink="/appointments/consultations" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])" mat-menu-item>
				{{ 'LAYOUT.LINKS.Appointments_list' | translate }}
			</div>
			<div class="header-link" routerLink="/appointments/call-history" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])" mat-menu-item>
				{{ 'LAYOUT.LINKS.Call_history' | translate }}
			</div>
		</mat-menu> -->

		<div class="navigations">
			<img src="assets/images/warnings.svg" alt="" style="margin-right: -20px;">
			<div class="header-link" routerLink="/warnings" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])">
				{{ 'LAYOUT.LINKS.Warnings' | translate }}
			</div>

			<div class="header-link" routerLink="/activity" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])">
				{{ 'LAYOUT.LINKS.Activity' | translate }}</div>

			<div class="header-link" routerLink="/heart-rate" routerLinkActive="active"
				*ngIf="checkRole(['patient', 'admin'])">{{ 'LAYOUT.LINKS.Heart_Rate' | translate }}</div>

			<div class="header-link" routerLink="/blood-pressure" routerLinkActive="active"
				*ngIf="checkRole(['patient', 'admin'])">{{ 'LAYOUT.LINKS.Blood_Pressure' | translate }}</div>

			<div class="header-link" routerLink="/respiration" routerLinkActive="active"
				*ngIf="checkRole(['patient', 'admin'])">{{ 'LAYOUT.LINKS.Respiration' | translate }}</div>

			<div class="header-link" routerLink="/charts" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])">
				{{ 'LAYOUT.LINKS.Charts' | translate }}</div>
			<!-- <div class="header-link" routerLink="/link-reports" routerLinkActive="active" *ngIf="checkRole(['patient'])">
				{{ 'LAYOUT.LINKS.Reports' | translate }}
			</div> -->
			<div class="header-link" routerLink="/questionnaires" routerLinkActive="active" *ngIf="checkRole(['patient'])">
				{{ 'LAYOUT.LINKS.Questionnaires' | translate }}
			</div>

			<div class="header-link" routerLink="/qr-code" routerLinkActive="active" *ngIf="checkRole(['patient'])">
				{{ 'LAYOUT.LINKS.Apps' | translate }}
			</div>
		</div>

		<div class="nav-bar-container">
			<div class="hamburger-icon" [matMenuTriggerFor]="navMenu" #t="matMenuTrigger" (click)="toggleNavBar($event, t)">
				<div class="bar1"></div>
				<div class="bar2"></div>
				<div class="bar3"></div>
			</div>
			<mat-menu #navMenu #beforeMenu="matMenu" xPosition="before">
				<menu mat-menu-item routerLink="/warnings" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])">
					{{ 'LAYOUT.LINKS.Warnings' | translate }}
				</menu>
				<menu mat-menu-item routerLink="/activity" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])">
					{{ 'LAYOUT.LINKS.Activity' | translate }}
				</menu>
				<menu mat-menu-item routerLink="/heart-rate" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])">
					{{ 'LAYOUT.LINKS.Heart_Rate' | translate }}
				</menu>
				<menu mat-menu-item routerLink="/blood-pressure" routerLinkActive="active"
					*ngIf="checkRole(['patient', 'admin'])">
					{{ 'LAYOUT.LINKS.Blood_Pressure' | translate }}
				</menu>
				<menu mat-menu-item routerLink="/respiration" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])">
					{{ 'LAYOUT.LINKS.Respiration' | translate }}
				</menu>
				<menu mat-menu-item routerLink="/charts" routerLinkActive="active" *ngIf="checkRole(['patient', 'admin'])">
					{{ 'LAYOUT.LINKS.Charts' | translate }}
				</menu>
				<!-- <menu mat-menu-item routerLink="/link-reports" routerLinkActive="active" *ngIf="checkRole(['patient'])">
					{{ 'LAYOUT.LINKS.Reports' | translate }}
				</menu> -->
				<menu mat-menu-item routerLink="/questionnaires" routerLinkActive="active" *ngIf="checkRole(['patient'])">
					{{ 'LAYOUT.LINKS.Questionnaires' | translate }}
				</menu>
				<menu mat-menu-item routerLink="/qr-code" routerLinkActive="active" *ngIf="checkRole(['patient'])">
					{{ 'LAYOUT.LINKS.Apps' | translate }}
				</menu>
			</mat-menu>
		</div>

		<div class="info-wrap">
			<app-language-change></app-language-change>
			<div class="circleImgContainer" (click)="settingsShown = !settingsShown">
				<img [src]="imageFile" alt="avatar" *ngIf="hasAvatar">
				<div *ngIf="!hasAvatar">
					{{ showInitials() }}
				</div>
			</div>
			<div *ngIf="settingsShown" class="settings-bar">
				<div class="image-and-name">
					<div class="circleImgContainer">
						<img [src]="imageFile" alt="avatar" *ngIf="hasAvatar">
						<div *ngIf="!hasAvatar">
							{{ showInitials() }}
						</div>
					</div>
					<div class="name">{{userName}}</div>
				</div>
				<div class="settings-item" (click)="openPopup(editProfilePopup)">
					<div class="settings-text">{{ 'LAYOUT.PROFILE.LINKS.Edit' | translate }}</div>
					<div class="setting-arrow">
						<svg xmlns="http://www.w3.org/2000/svg" width="8.969" height="13.519" viewBox="0 0 8.969 13.519">
							<path id="Back_Arrow" data-name="Back Arrow"
								d="M1316.591,3114.725l7.534,6.694-7.534,6.824-1.435-1.288,6.1-5.537-6.1-5.407Z"
								transform="translate(-1315.156 -3114.725)" fill="#a7a7a7" />
						</svg>
					</div>
				</div>
				<div class="settings-item" (click)="openPopup(changePasswordPopup)">
					<div class="settings-text">{{ 'LAYOUT.PROFILE.LINKS.Change_password' | translate }}</div>
					<div class="setting-arrow">
						<svg xmlns="http://www.w3.org/2000/svg" width="8.969" height="13.519" viewBox="0 0 8.969 13.519">
							<path id="Back_Arrow" data-name="Back Arrow"
								d="M1316.591,3114.725l7.534,6.694-7.534,6.824-1.435-1.288,6.1-5.537-6.1-5.407Z"
								transform="translate(-1315.156 -3114.725)" fill="#a7a7a7" />
						</svg>
					</div>
				</div>
				<div class="settings-item" (click)="openPopup(doctorsPopup)">
					<div class="settings-text">{{ 'LAYOUT.PROFILE.LINKS.Emergency_contacts' | translate}}</div>
					<div class="setting-arrow">
						<svg xmlns="http://www.w3.org/2000/svg" width="8.969" height="13.519" viewBox="0 0 8.969 13.519">
							<path id="Back_Arrow" data-name="Back Arrow"
								d="M1316.591,3114.725l7.534,6.694-7.534,6.824-1.435-1.288,6.1-5.537-6.1-5.407Z"
								transform="translate(-1315.156 -3114.725)" fill="#a7a7a7" />
						</svg>
					</div>
				</div>
				<!-- <div class="settings-item" (click)="editProfile()">
					<div class="settings-text">{{ 'LAYOUT.PROFILE.LINKS.Payment_method' | translate }}</div>
					<div class="setting-arrow">
						<svg xmlns="http://www.w3.org/2000/svg" width="8.969" height="13.519" viewBox="0 0 8.969 13.519">
							<path id="Back_Arrow" data-name="Back Arrow"
								d="M1316.591,3114.725l7.534,6.694-7.534,6.824-1.435-1.288,6.1-5.537-6.1-5.407Z"
								transform="translate(-1315.156 -3114.725)" fill="#a7a7a7" />
						</svg>
					</div>
				</div>
				<div class="settings-item" (click)="editProfile()">
					<div class="settings-text">{{ 'LAYOUT.PROFILE.LINKS.Payment_history' | translate }}</div>
					<div class="setting-arrow">
						<svg xmlns="http://www.w3.org/2000/svg" width="8.969" height="13.519" viewBox="0 0 8.969 13.519">
							<path id="Back_Arrow" data-name="Back Arrow"
								d="M1316.591,3114.725l7.534,6.694-7.534,6.824-1.435-1.288,6.1-5.537-6.1-5.407Z"
								transform="translate(-1315.156 -3114.725)" fill="#a7a7a7" />
						</svg>
					</div>
				</div> -->
				<div class="settings-item" (click)="redirectToPayments()">
					<div class="settings-text">{{ 'LAYOUT.PROFILE.LINKS.Payments' | translate }}</div>
					<div class="setting-arrow">
						<svg xmlns="http://www.w3.org/2000/svg" width="8.969" height="13.519" viewBox="0 0 8.969 13.519">
							<path id="Back_Arrow" data-name="Back Arrow"
								d="M1316.591,3114.725l7.534,6.694-7.534,6.824-1.435-1.288,6.1-5.537-6.1-5.407Z"
								transform="translate(-1315.156 -3114.725)" fill="#a7a7a7" />
						</svg>
					</div>
				</div>

				<div class="settings-item" (click)="redirectToRequestMedicalObservation()">
					<div class="settings-text">{{ 'LAYOUT.PROFILE.LINKS.Request_medical_observation' | translate }}</div>
					<div class="setting-arrow">
						<svg xmlns="http://www.w3.org/2000/svg" width="8.969" height="13.519" viewBox="0 0 8.969 13.519">
							<path id="Back_Arrow" data-name="Back Arrow"
								d="M1316.591,3114.725l7.534,6.694-7.534,6.824-1.435-1.288,6.1-5.537-6.1-5.407Z"
								transform="translate(-1315.156 -3114.725)" fill="#a7a7a7" />
						</svg>
					</div>
				</div>

				<div class="text-center" (click)="logOut()">
					<button class="login-register-button">{{ 'LAYOUT.PROFILE.LINKS.Logout' | translate }}</button>
				</div>
			</div>

			<div class="flex align-items-center gap05 pointer" (click)="redirectToBuyCredits()">
				<img src="assets/images/credits.svg" alt="credits">
				<span>
					{{ userCredits}} {{ 'LAYOUT.LABELS.Credits' | translate }}
				</span>
			</div>
		</div>
	</div>
</header>
<dialog #changePasswordPopup>
	<div class="popupHeader">
		<div class="login-register-title">{{ 'LAYOUT.PROFILE.CHANGE_PASSWORD.Title' | translate }}</div>
		<div class="rightPart" (click)="closePopup(changePasswordPopup)">
			<svg width="15px" height="15px" viewBox="0 0 460.775 460.775">
				<path fill="#707070"
					d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z" />
			</svg>
		</div>
	</div>
	<div class="flexer">
		<form novalidate (ngSubmit)="onChangePassSubmit(changePassForm, changePasswordPopup)" #changePassForm="ngForm"
			samePasswordValidator>
			<div class="input-wrap" [ngClass]="{'has-error': changePassSubmited && oldPassword?.errors?.required }">
				<input class="register-input" [(ngModel)]="changePassModel.oldPassword" name="oldPassword"
					#oldPassword="ngModel" type="password" placeholder="{{'LAYOUT.PROFILE.CHANGE_PASSWORD.PLACEHOLDERS.Old_password' | translate }}" required>
				<span class="error" *ngIf="changePassSubmited && oldPassword?.errors?.required">
					{{ 'LAYOUT.PROFILE.CHANGE_PASSWORD.ERRORS.Old_password_required' | translate }}
				</span>
			</div>
			<div class="input-wrap" [ngClass]="{'has-error': changePassSubmited && newPassword?.errors }">
				<input class="register-input" [(ngModel)]="changePassModel.newPassword" name="newPassword"
					#newPassword="ngModel" type="password" placeholder="{{'LAYOUT.PROFILE.CHANGE_PASSWORD.PLACEHOLDERS.New_password' | translate }}" required minlength="6">
				<span class="error" *ngIf="changePassSubmited && newPassword?.errors?.required">
					{{ 'LAYOUT.PROFILE.CHANGE_PASSWORD.ERRORS.New_password_required' | translate }}
				</span>
				<span class="error" *ngIf="changePassSubmited && newPassword?.errors?.minlength">
					{{ 'LAYOUT.PROFILE.CHANGE_PASSWORD.ERRORS.Min_length' | translate }}
				</span>
			</div>
			<div class="input-wrap"
				[ngClass]="{'has-error': changePassSubmited && (repeatPassword?.errors || changePassForm.errors?.passwordsNotSame) }">
				<input class="register-input" [(ngModel)]="changePassModel.repeatPassword" name="repeatPassword"
					#repeatPassword="ngModel" type="password" placeholder="{{'LAYOUT.PROFILE.CHANGE_PASSWORD.PLACEHOLDERS.Repeat_password' | translate }}" required>
				<span class="error" *ngIf="changePassSubmited && repeatPassword?.errors?.required">
					{{ 'LAYOUT.PROFILE.CHANGE_PASSWORD.ERRORS.Repeat_password_required' | translate }}
				</span>
				<span class="error"
					*ngIf="changePassSubmited && changePassForm.errors?.passwordsNotSame && !(repeatPassword?.errors?.required)">
					{{ 'LAYOUT.PROFILE.CHANGE_PASSWORD.ERRORS.Match_password' | translate }}
				</span>
			</div>
			<button class="login-register-button" type="submit">{{ 'LAYOUT.PROFILE.CHANGE_PASSWORD.BUTTONS.Send' | translate
				}}</button>
		</form>
	</div>
</dialog>

<dialog #editProfilePopup>
	<div class="popupHeader">
		<div class="login-register-title">{{ 'LAYOUT.PROFILE.EDIT.Title' | translate }}</div>
		<div class="rightPart" (click)="closePopup(editProfilePopup)">
			<svg width="15px" height="15px" viewBox="0 0 460.775 460.775">
				<path fill="#707070"
					d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z" />
			</svg>
		</div>
	</div>
	<div class="flexer">
		<form novalidate (ngSubmit)="onEditProfileSubmit(editInfoForm, editProfilePopup)" #editInfoForm="ngForm">
			<div class="imageUploader">
				<div class="flexer circleImgContainer">
					<img [src]='imageFile'>
					<div class="addPhoto">
						<label for="profileImg">
							<svg xmlns="http://www.w3.org/2000/svg" width="53.356" height="53.356" viewBox="0 0 53.356 53.356">
								<g id="Group_25492" data-name="Group 25492" transform="translate(-228.322 -155.5)">
									<circle id="Ellipse_3936" data-name="Ellipse 3936" cx="26.678" cy="26.678" r="26.678"
										transform="translate(228.322 155.5)" fill="#f28900" />
									<g id="_711191" data-name="711191" transform="translate(239.36 120.986)">
										<g id="Group_17961" data-name="Group 17961" transform="translate(0 47.104)">
											<g id="Group_17960" data-name="Group 17960" transform="translate(0 0)">
												<path id="Path_13065" data-name="Path 13065"
													d="M27.539,51.456H24.275l-1.8-2.72A3.745,3.745,0,0,0,19.379,47.1H11.9a3.745,3.745,0,0,0-3.094,1.632L7,51.456H3.74A3.729,3.729,0,0,0,0,55.2V68.387a3.729,3.729,0,0,0,3.74,3.74h23.8a3.729,3.729,0,0,0,3.74-3.74V55.2A3.729,3.729,0,0,0,27.539,51.456ZM15.64,69.067a8.415,8.415,0,1,1,8.432-8.4A8.43,8.43,0,0,1,15.64,69.067ZM27.063,56.726h-1.5a1.106,1.106,0,0,1,0-2.21h1.36a1.1,1.1,0,0,1,1.156,1.054A1.076,1.076,0,0,1,27.063,56.726Z"
													transform="translate(0 -47.104)" fill="#f3f4f5" />
											</g>
										</g>
										<g id="Group_17963" data-name="Group 17963" transform="translate(10.948 55.978)">
											<g id="Group_17962" data-name="Group 17962">
												<path id="Path_13066" data-name="Path 13066"
													d="M169.556,180.736a4.675,4.675,0,1,0,4.692,4.658A4.7,4.7,0,0,0,169.556,180.736Z"
													transform="translate(-164.864 -180.736)" fill="#f3f4f5" />
											</g>
										</g>
									</g>
								</g>
							</svg>
							<input name="image" id="profileImg" type="file" (change)="onProfileImgSelect($event.target.files)">
						</label>
					</div>
				</div>
			</div>
			<div class="input-wrap" [ngClass]="{'has-error': editProfileSubmited && first_name?.errors?.required }">
				<input class="register-input" [(ngModel)]="editProfileModel.first_name" name="first_name" #first_name="ngModel" type="text"
					placeholder="{{'LAYOUT.PROFILE.EDIT.PLACEHOLDERS.First_name' | translate }}" required>
				<span class="error" *ngIf="editProfileSubmited && first_name?.errors?.required">
					{{ 'LAYOUT.PROFILE.EDIT.ERRORS.First_name_required' | translate }}
				</span>
			</div>
			<div class="input-wrap" [ngClass]="{'has-error': editProfileSubmited && last_name?.errors?.required }">
				<input class="register-input" [(ngModel)]="editProfileModel.last_name" name="last_name" #last_name="ngModel" type="text"
					placeholder="{{'LAYOUT.PROFILE.EDIT.PLACEHOLDERS.Last_name' | translate }}
					" required>
				<span class="error" *ngIf="editProfileSubmited && last_name?.errors?.required">
					{{ 'LAYOUT.PROFILE.EDIT.ERRORS.Last_name_required' | translate }}
				</span>
			</div>
			<div class="input-wrap" [ngClass]="{'has-error': editProfileSubmited && weight?.errors?.required }">
				<input class="register-input" [(ngModel)]="editProfileModel.weight" name="weight" 
					#weight="ngModel" type="number"	min="0" max="300"
					placeholder="{{'LAYOUT.PROFILE.EDIT.PLACEHOLDERS.Weight' | translate }}" required>
				<span class="error" *ngIf="editProfileSubmited && weight?.errors?.required">
					{{ 'LAYOUT.PROFILE.EDIT.ERRORS.Weight_required' | translate }}
				</span>
			</div>
			<div class="input-wrap" [ngClass]="{'has-error': editProfileSubmited && height?.errors?.required }">
				<input class="register-input" [(ngModel)]="editProfileModel.height" name="height" 
					#height="ngModel" type="number" min="0" max="300"
					placeholder="{{'LAYOUT.PROFILE.EDIT.PLACEHOLDERS.Height' | translate }}" required>
				<span class="error" *ngIf="editProfileSubmited && height?.errors?.required">
					{{ 'LAYOUT.PROFILE.EDIT.ERRORS.Height_required' | translate }}
				</span>
			</div>
			<div class="input-wrap" [ngClass]="{ 'has-error': editProfileSubmited && birth_date?.errors }">
				<label for="birth_date">{{'LAYOUT.PROFILE.EDIT.PLACEHOLDERS.Birth_date' | translate}}</label>
				<input class="register-input" type="date" [(ngModel)]="editProfileModel.birth_date" #birth_date="ngModel" name="birth_date" id="birth_date">
				<span class="error" *ngIf="editProfileModel.birth_date?.errors?.required">
					{{'REGISTER_FORM.ERRORS.Birth_date_req' | translate}}
				</span>
			</div>
			<div class="input-wrap" [ngClass]="{'has-error': editProfileSubmited && email?.errors }">
				<input class="register-input" [(ngModel)]="editProfileModel.email" name="email" #email="ngModel" type="text"
					placeholder="{{'LAYOUT.PROFILE.EDIT.PLACEHOLDERS.Email' | translate }}" required>
				<span class="error" *ngIf="editProfileSubmited && email?.errors?.required">
					{{ 'LAYOUT.PROFILE.EDIT.ERRORS.Email_invalid' | translate }}
				</span>
			</div>
			<div class="input-wrap" [ngClass]="{'has-error': editProfileSubmited && phoneNumber?.errors }">
				<input class="register-input" [(ngModel)]="editProfileModel.phoneNumber" name="phoneNumber"
					#phoneNumber="ngModel" type="text" placeholder="{{ 'LAYOUT.PROFILE.EDIT.PLACEHOLDERS.Mobile' | translate }}" required
					pattern="\+\d{12}$">
				<span class="error" *ngIf="editProfileSubmited && phoneNumber?.errors?.required">
					{{ 'LAYOUT.PROFILE.EDIT.ERRORS.Phone_required' | translate }}
				</span>
				<span class="error" *ngIf="editProfileSubmited && phoneNumber?.errors?.pattern">
					{{ 'LAYOUT.PROFILE.EDIT.ERRORS.Phone_invalid' | translate }}
				</span>
			</div>
			<div class="register-genders">
				<input autocomplete="off" [(ngModel)]="editProfileModel.gender" name="gender" class="gender" type="radio"
					value="male" id="gender-male">
				<label for="gender-male">{{ 'LAYOUT.PROFILE.EDIT.LABELS.Gender_male' | translate }}</label>
				<input autocomplete="off" [(ngModel)]="editProfileModel.gender" name="gender" class="gender" type="radio"
					value="female" id="gender-female">
				<label for="gender-female">{{ 'LAYOUT.PROFILE.EDIT.LABELS.Gender_female' | translate }}</label>
			</div>
			<button class="login-register-button" type="submit">{{ 'LAYOUT.PROFILE.EDIT.BUTTONS.Send' | translate }}</button>
		</form>
	</div>
</dialog>

<dialog #doctorsPopup>
	<div class="popupHeader">
		<div class="login-register-title">{{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.Title' | translate }}</div>
		<div class="rightPart" (click)="closePopup(doctorsPopup)">
			<svg width="15px" height="15px" viewBox="0 0 460.775 460.775">
				<path fill="#707070"
					d="M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z" />
			</svg>
		</div>
	</div>
	<div class="flexer">
		<form novalidate (ngSubmit)="onDoctorsSubmit(doctorsPopup)">
			<div *ngFor="let doctor of doctorModel.doctors; let i = index" class="doctor-row">
				<div class="input-wrap" [ngClass]="{'has-error': doctorsSubmited && doctorName?.errors }">
					<input class="register-input" type="text" [(ngModel)]="doctorModel.doctors[i].name" [name]="'doctorName' + i"
						#doctorName="ngModel" required>
					<span class="error" *ngIf="doctorsSubmited && doctorName?.errors?.required">
						{{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.ERRORS.Doctor_name_required' | translate }}
					</span>
				</div>
				<div class="input-wrap" [ngClass]="{'has-error': doctorsSubmited && doctorEmail?.errors }">
					<input class="register-input" type="text" [(ngModel)]="doctorModel.doctors[i].email"
						[name]="'doctroEmail' + i" #doctorEmail="ngModel" required>
					<span class="error" *ngIf="doctorsSubmited && doctorEmail?.errors?.required">
						{{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.ERRORS.Doctor_email_required' | translate }}
					</span>
				</div>
				<div style="cursor: pointer;">
					<img src="assets/images/delete.svg" (click)="deleteDoctor(i)">
				</div>
			</div>
			<div style="text-align: center;">
				<div class="add-more" (click)="addDoctor()">
					<img src="assets/images/plus.svg">
					<span class="addMoreText">{{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.BUTTONS.Add' | translate }}</span>
				</div>
			</div>
			<button class="login-register-button" type="submit">{{ 'LAYOUT.PROFILE.EMERGENCY_CONTACTS.BUTTONS.Submit' |
				translate }}</button>
		</form>
	</div>
</dialog>

<div class="content-wrap">
	<div class="container">
		<div class="page-title" *ngIf="!mobileAppObj && title">{{title}}</div>
		<router-outlet></router-outlet>
	</div>
</div>
<div class="chat-wrapper" title="live chat">
	<app-chat-button 
		[room]="chatRoom" 
		[visitId]="visitId" 
		[chatTitle]="chatTitle" 
		[userId]="userId" 
		[name]="userName"
		[userUid]="userUid"
		></app-chat-button>
</div>

<img src="assets/images/lay-left.svg" alt="" hidden>
<img src="assets/images/lay-right.svg" alt="" hidden>
<img src="assets/images/lay-prone.svg" alt="" hidden>
<img src="assets/images/lay-supine.svg" alt="" hidden>
<img src="assets/images/standing.svg" alt="" hidden>
<img src="assets/images/sitting.svg" alt="" hidden>
<img src="assets/images/walking.svg" alt="" hidden>
<img src="assets/images/running.svg" alt="" hidden>
<img src="assets/images/driving.svg" alt="" hidden>
<img src="assets/images/credits.svg" alt="" hidden>