<div class="dialog-wrapper">
    <div class="dialog-header">
        <div class="dialog-title">
            {{ data.title | translate }}
        </div>
        <div class="close" mat-dialog-close="">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="dialog-body">
        <div class="dialog-message">
            {{ data.message | translate }}
        </div>
    </div>
    <div class="dialog-footer">
        <div class="button-close" mat-dialog-close="">
            {{ buttonText | translate }}
        </div>
    </div>
</div>