import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from 'ngx-webstorage';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

export interface ChatFile {
  fileUrl: string,
  thumbnail?: string
  mimeType: string,
  fileName: string
}

export enum ChatMessageType {
  "message" = 1,
  "VideoCallMessage" = 2
}

export interface VideoCallProperties {
  type?: string | number | undefined | null, // 1 - started, 2 - ended, 3 - missed
  duration?: string | number | undefined | null //in ms
}

export interface ChatMessage {
  _id?: number | string | undefined,
  time: string | number,
  text: string | undefined,
  senderName?: string | undefined,
  avatarUrl?: string | undefined,
  isResponse: boolean,
  files?: ChatFile[] | undefined,
  read?: boolean,
  room?: string | undefined,
  seenBy?: string[] | [],
  seenID?: string[],
  type?: ChatMessageType | undefined,
  video?: VideoCallProperties | undefined
}

export enum ChatDirection {
  "next" = "next",
  "prev" = "prev"
}

export interface ChatHistoryCriteria {
  size: string | number,
  time: string | number,
  room: string | undefined,
  direction: ChatDirection
}

export enum VideoCallStatus {
  "initiated" = "initiated",
  "started" = "started",
  "cancelled" = "cancelled",
  "ended" = "ended"
}

@Injectable({
  providedIn: 'root'
})
export class ChatService {
  private visitUrl = environment.protocol + environment.apiVer2URL + '/visit';
  private visitId: any;
  private unreadMessages: BehaviorSubject<Map<string, Number>> = new BehaviorSubject(new Map());
  public unreadMessages$: Observable<any> = this.unreadMessages.asObservable();
  private unsentMessage: { text: string, attachedFiles: { file: File, chatObj: ChatFile }[] };
  private callStatus: BehaviorSubject<string> = new BehaviorSubject(undefined);
  public callStatus$: Observable<any> = this.callStatus.asObservable();
  public bookingUrl = environment.protocol + environment.bookingAPI + '/';

  constructor(private http: HttpClient, private storage: LocalStorageService) {
    this.visitId = this.storage.retrieve('loggedUser').visit_id;
    this.retrieveUnsentMessage();
  }

  storeChatHistory(chatHistory: Map<number, ChatMessage>) {
    localStorage.setItem('chatHistory', JSON.stringify(Array.from(chatHistory)));
  }

  retrieveChatHistory() {
    let chatHistory = localStorage.getItem('chatHistory');
    if (chatHistory) {
      let parsedHistory = JSON.parse(chatHistory);
      let historyMap = new Map<Date, ChatMessage>(parsedHistory);
      return historyMap;
    } else {
      return new Map<Date, ChatMessage>();
    }
  }

  uploadFile(visitId: any, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    let url: any = `${this.visitUrl}/${visitId}/upload-doc`;
    return this.http.post(url, formData);
  }

  removeFile(visitId: any, fileName: string) {
    let url: any = `${this.visitUrl}/${visitId}/remove-doc`;
    let body = {
      file: fileName
    }

    return this.http.post(url, body);
  }

  setUnreadMessagesCount(room: any, count: number) {
    const currentMap = this.unreadMessages.getValue();
    currentMap.set(room, count);
    this.unreadMessages.next(currentMap);
  }

  getUnsentMessage(): { text: string, attachedFiles: { file: File, chatObj: ChatFile }[] } {
    return this.unsentMessage;
  }

  setUnsentMessage(message: { text: string, attachedFiles: { file: File, chatObj: ChatFile }[] }): void {
    this.unsentMessage = message;
    this.storeUnsentMessage();
  }

  private storeUnsentMessage(): void {
    let message = JSON.stringify(this.unsentMessage);
    localStorage.setItem('unsentMessage', message);
  }

  private retrieveUnsentMessage(): void {
    const storedUnsentMessage = localStorage.getItem('unsentMessage');
    if (storedUnsentMessage) {
      this.unsentMessage = JSON.parse(storedUnsentMessage);
    }
  }

  clearUnsentMessage(): void {
    this.unsentMessage = { text: undefined, attachedFiles: [] };
    localStorage.removeItem('unsentMessage');
  }

  setCallStatus(status: string) {
    this.callStatus.next(status);
  }

  getUserCredits(userUid: string) {
    let url = this.bookingUrl + 'patient/credit';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getUserActiveSubscription(userUid: string) {
    let url = this.bookingUrl + 'patient/active-subscription';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getVideoCallPricePerMinute(userUid: string) {
    let url = this.bookingUrl + 'patient/medical-service/video-call/price';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getPatientObservationStatus(userUid: string) {
    let url = this.bookingUrl + 'patient/observation-status';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });

  }

}
