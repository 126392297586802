<div class="dialog-wrapper">
    <div class="dialog-header">
        <div class="dialog-title">
            {{ data.title | translate }}
        </div>
        <div class="close" mat-dialog-close="cancel">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <div class="dialog-body">
        <div class="dialog-message">
            {{ message }}
        </div>
    </div>
    <div class="dialog-footer">

        <div class="flex gap05 justify-content-center align-items-center">
            <div class="button cancel" mat-dialog-close="cancel">
                {{ 'CONFIRMATION_DIALOG.BUTTONS.Cancel' | translate }}
            </div>
    
            <div class="button" mat-dialog-close="continue">
                {{ 'CONFIRMATION_DIALOG.BUTTONS.Continue' | translate }}
            </div>
        </div>
        
    </div>
</div>