import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AppointmentsService } from 'src/app/appointments/services/appointments.service';
import { IDoctor, IDoctorReview } from 'src/app/appointments/types-and-const/appointment-types';
import { InformationDialogComponent } from 'src/app/billing/dialogs/information-dialog/information-dialog.component';

@Component({
  selector: 'app-reviews-dialog',
  templateUrl: './reviews-dialog.component.html',
  styleUrls: ['./reviews-dialog.component.scss']
})
export class ReviewsDialogComponent implements OnInit {
  public doctorData: IDoctor = this.data.doctorData;
  public userUid: string = this.data.userUid;
  public reviews: IDoctorReview[] = this.doctorData.reviews || [];
  public reviewText: string = '';
  public reviewRating: number = 1;
  public hoveredRating: number = 1;
  public reviewSubmitted: boolean = false;

  constructor(
    private dialogRef: MatDialogRef<ReviewsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { doctorData: IDoctor, userUid: string },
    private dialog: MatDialog,
    private appointmentsService: AppointmentsService
  ) { }

  ngOnInit(): void {
  }

  writeReview() {
    this.reviewSubmitted = true;
    this.appointmentsService.writeDoctorReview(this.userUid, this.doctorData.id, this.reviewText, this.reviewRating).subscribe({
      next: (response: any) => {
        if (response) {
          this.showInfoDialog();
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    });
  }

  protected showInfoDialog() {
    let dialogRef = this.dialog.open(InformationDialogComponent, {
      data: {
        title: 'BILLING.DIALOGS.INFORMATION_DIALOG.TITLES.Review_approval',
        message: 'BILLING.DIALOGS.INFORMATION_DIALOG.MESSAGES.Review_approval',
        buttonText: 'BILLING.DIALOGS.INFORMATION_DIALOG.BUTTONS.Close'
      },
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe({
      next: (result: any) => { }
    });
  }

  public close() {
    this.dialogRef.close();
  }

}
