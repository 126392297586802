<div class="dialog-wrapper">
    
    <div class="dialog-header">

        <div class="font-semibold font-20">
            {{ data.title | translate }}
        </div>
        
        <div class="close pointer" (click)="close()">
            <mat-icon>close</mat-icon>
        </div>
    
    </div>

    <div class="dialog-body">
        <div class="font-regular font-16">
            {{ data.message | translate }}
        </div>
    </div>

    <div class="dialog-footer">
        <button class="blue-button" (click)="close()">
            {{ data.buttonText | translate }}
        </button>
    </div>

</div>