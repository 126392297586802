export interface IPaymentHistory {
    id?: number | undefined | null;
    amount?: number | undefined | null;
    currency?: string | undefined | null;
    description?: string | undefined | null;
    orderId?: string | undefined | null;
    status?: string | undefined | null;
    createdAt?: string | number | Date | undefined | null;
    client?: {
        id: number | undefined | null,
        email: string | undefined | null,
        name: string | undefined | null
    };
    singlePrice?: number | undefined | null;
    quantity?: number | undefined | null;
}

export interface ICreditTransaction {
    id?: number | undefined | null;
    createdAt?: number | undefined | null;
    description?: string | undefined | null;
    amount?: number | undefined | null;
}

export enum SubscriptionTypes {
    "Basic" = "Basic",
    "Standard" = "Standard",
    "Premium" = "Premium"
}

export interface ISubscriptionPlan {
    id: number | undefined | null;
    type: string | undefined | null;
    period: string | undefined | null;
    price: number | undefined | null;
    braceletExam: boolean | undefined | null;
    braceletAndMykiExam: boolean | undefined | null;
    observations24h: boolean | undefined | null;
    conclusions: number | undefined | null;
    videoCalls: boolean | undefined | null;
    directChat: boolean | undefined | null;
    sosSignal: boolean | undefined | null;
    locationTransmission: boolean | undefined | null;
    carePlan: boolean | undefined | null;
    dataStorageMonths: number | undefined | null;
    startDate?: string | number | Date | undefined | null;
    endDate?: string | number | Date | undefined | null;
    isDefault?: boolean | undefined | null;
}

export enum MedicalServicesNames {
    "BRACELET_EXAM" = "bracelet_exam",
    "BRACELET_MYKI_EXAM" = "bracelet_and_myki_exam",
    "OBSERVATIONS24H" = "observations_24h",
    "CONCLUSIONS" = "conclusions",
    "VIDEO_CALLS" = "video_calls",
    "DIRECT_CHAT" = "direct_chat",
    "SOS" = "sos_signal",
    "LOCATION_TRANSMISSION" = "local_transmission",
    "CARE_PLAN" = "care_plan",
    "DATA_STORAGE" = "data_storage_months"
}

export interface IMedicalService {
    id: number | undefined | null;
    name: string | undefined | null;
    paid?: boolean | undefined | null;
    price: number | undefined | null;
    unit: string | undefined | null;
}

export interface IUserCredits {
    credits: number | undefined | null;
    giftedCredits: number | undefined | null;
    moneySpend: number | undefined | null;
}

export interface ICreditPackage {
    id: number | undefined | null;
    name?: string | undefined | null
    price: number | undefined | null;
    credits: number | undefined | null;
}

export type Item = {
    itemId: number;
    quantity: number;
    type: string;
};

export enum ItemType {
    "PLAN" = "PLAN",
    "CREDITS" = "CREDITS"
}

export type PaymentConfig = {
    backendUrl: string;
    externalClientId: number;
    currency: string;
    fullPrice: number;
    items: Item[];
    userEmail: string;
    logoUrl: string;
    itemImgUrl: string;
    redirectOptions: {
        text: string;
        successUrl: string;
        backUrl: string;
    };
    itemName?: string;
    itemDescription?: string;
    cssUrl?: string;
    authToken?: string;
    withPromoCodes?: boolean;
    withInvoiceData?: boolean;
    withSubscriptions?: boolean;
};

export enum RedirectRouteNames {
    'Appointments' = 'Appointments',
    'MedicalServices' = 'MedicalServices',
    'Subscriptions' = 'Subscriptions',
    'Request Medical Observation' = 'Request Medical Observation'
}

export type ObservationStatus = {
    underObservation: boolean;
    hasPaidForObservation: boolean;
}
