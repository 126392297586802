import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-choose-payment-type-dialog',
  templateUrl: './choose-payment-type-dialog.component.html',
  styleUrls: ['./choose-payment-type-dialog.component.scss']
})
export class ChoosePaymentTypeDialogComponent implements OnInit {
  public selectedPaymentOption: string = 'credits';
  public price: number = this.data.price;
  
  constructor(private dialogRef: MatDialogRef<ChoosePaymentTypeDialogComponent>, @Inject(MAT_DIALOG_DATA) private data: {
    price: number
  }) { }

  ngOnInit(): void {
  }

  continue() {
    this.dialogRef.close(this.selectedPaymentOption);
  }

  close() {
    this.dialogRef.close();
  }

}
