import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private bookingUrl = environment.protocol + environment.bookingAPI + '/';
  private unsuccessfulPaymentUrl: BehaviorSubject<any> = new BehaviorSubject(undefined); // Where to redirect after buying enough credits for service
  public unsuccesfulPaymentUrl$: Observable<any> = this.unsuccessfulPaymentUrl.asObservable();

  constructor(private http: HttpClient) { }

  setUnsuccessfulPaymentUrl(url: string = undefined, routeName: string = undefined) {
    if (url && routeName) {
      this.unsuccessfulPaymentUrl.next({ url, routeName });
    } else {
      this.unsuccessfulPaymentUrl.next(undefined);
    }
  }

  getPaymentConfigId(config: string) {
    let url = this.bookingUrl + 'api/payment/config';
    let body = {
      config
    }

    return this.http.post(url, body);
  }

  getUserCredits(userUid: string) {
    let url = this.bookingUrl + 'patient/credit';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getAllCreditPackages(userUid: string) {
    let url = this.bookingUrl + 'patient/credit/package/all';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getAllSubscriptions(userUid: string) {
    let url = this.bookingUrl + 'patient/subscription-type/all';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getUserActiveSubscription(userUid: string) {
    let url = this.bookingUrl + 'patient/active-subscription';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getAllMedicalServices(userUid: string) {
    let url = this.bookingUrl + 'patient/medical-service/all';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getUserPayments(userUid: string, page: number, pageSize: number) {
    let url = this.bookingUrl + `patient/payments?page=${page}&pageSize=${pageSize}`;

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getUserCreditTransactions(userUid: string, page: number, pageSize: number) {
    let url = this.bookingUrl + `patient/credit/transactions?page=${page}&pageSize=${pageSize}`;

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  createPaymentWithCredits(userUid: string, medicalServiceId: number) {
    let url = this.bookingUrl + 'patient/credit/pay-for-service';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    const body: any = {
      medicalServiceId
    }

    return this.http.post(url, body, { headers });
  }

  buySubscription(userUid: string, subscriptionTypeId: number, externalClientId: number) {
    let url = this.bookingUrl + 'patient/credit/subscribe';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    const body = {
      subscriptionTypeId,
      externalClientId
    }

    return this.http.post(url, body, { headers });
  }

  getVideoCallPricePerMinute(userUid: string) {
    let url = this.bookingUrl + 'patient/medical-service/video-call/price';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  requestMedicalObservation(userUid: string, days: number) {
    let url = this.bookingUrl + 'patient/observation/activate';

    let body: any = {
      days
    };
    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.post(url, body, { headers });
  }

  getPatientObservationStatus(userUid: string) {
    let url = this.bookingUrl + 'patient/observation-status';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });

  }

  getMedicalServiceById(userUid: string, medicalServiceId: number) {
    let url = this.bookingUrl + `patient/medical-service/${medicalServiceId}`;

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

  getMedicalObservationServicePrice(userUid: string) {
    let url = this.bookingUrl + 'patient/medical-service/observation/price';

    const headers = new HttpHeaders({
      'userUid': userUid
    });

    return this.http.get(url, { headers });
  }

}
