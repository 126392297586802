import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppointmentsService, DOCTORS_MOCK_DATA } from '../services/appointments.service';
import { MatDialog } from '@angular/material/dialog';
import { ReviewsDialogComponent } from './components/reviews-dialog/reviews-dialog.component';
import { NoopScrollStrategy } from '@angular/cdk/overlay';
import { IDoctor, IMedicalSpecialty } from '../types-and-const/appointment-types';
import { LocalStorageService } from 'ngx-webstorage';

@Component({
  selector: 'app-doctor',
  templateUrl: './doctor.component.html',
  styleUrls: ['./doctor.component.scss']
})
export class DoctorComponent implements OnInit {
  public doctorData: IDoctor;
  public doctorId: number;
  private userUid: string = this.localStorage.retrieve('loggedUser')['user_booking_uid'];
  private userId: number = this.localStorage.retrieve('loggedUser').id;
  public isFavorite: boolean;
  public showLongResume: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private matDialog: MatDialog,
    private appointmentsService: AppointmentsService,
    private router: Router,
    private localStorage: LocalStorageService) {
  }

  ngOnInit(): void {
    this.route.params.subscribe((params: any) => {
      if (params.id) {
        this.doctorId = Number(params.id);
        this.getDoctorData();
      }
    });
  }

  protected getDoctorData(): void {
    if (!this.doctorId) {
      return;
    }

    this.appointmentsService.getDoctorBookingInfoById(this.userUid, this.doctorId).subscribe({
      next: (result: any) => {
        // this.doctorData = result.data;
        if (result) {
          this.doctorData = result;
          this.isFavorite = this.doctorData.isFavorite;
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { this.getDoctorReviews() }
    });
  }

  protected getDoctorReviews() {
    if (!this.doctorId) {
      return;
    }

    this.appointmentsService.getDoctorReviews(this.userUid, this.doctorId).subscribe({
      next: (result: any) => {
        if (result) {
          this.doctorData.reviews = result;
        } else {
          this.doctorData.reviews = [];
        }
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    })

  }

  public addOrRemoveFavorite(): void {
    this.isFavorite = !this.isFavorite;
    this.appointmentsService.addOrRemoveDoctorToFavorites(this.userUid, this.doctorId, this.isFavorite).subscribe({
      next: (response: any) => {
      },
      error: (err: any) => console.error(err),
      complete: () => { }
    })
    // api calls
  }

  public bookConsultation() {
    if (!this.doctorId) {
      return;
    }

    this.router.navigate(['/appointments/book-consultation', this.doctorId]);
  }

  public openReviews() {

    let dialogRef = this.matDialog.open(ReviewsDialogComponent, {
      data: {
        id: this.doctorId,
        doctorData: this.doctorData,
        userUid: this.userUid
      },
      scrollStrategy: new NoopScrollStrategy(),
      autoFocus: false,
      restoreFocus: false
    });

    dialogRef.afterClosed().subscribe((result) => { });
  }

}
